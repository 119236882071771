import { cn } from 'utils/cn'
import { Icon, IconName } from './Icon'
import s from './IconButton.module.sass'
import { RouteLink, RouteLinkRoute } from './NavLink'

export interface Props {
	name: IconName
	label?: React.ReactNode
	title?: string
	variant?: string
}

export function IconButton({
	name,
	label,
	title,
	buttonRef,
	onClick,
	variant,
}: Props & {
	buttonRef?: React.Ref<HTMLButtonElement>
	onClick?: React.MouseEventHandler
}) {
	return (
		<span className={s.Container}>
			<button
				ref={buttonRef}
				onClick={onClick}
				className={cn('btn', variant ? `btn-${variant}` : 'btn-unstyled')}
				title={title}>
				<span className={s.Inside}>
					<span className={s.Icon}>
						<Icon name={name} />
					</span>
					{label && (
						<>
							{' '}
							<span className={s.Container}>{label}</span>
						</>
					)}
				</span>
			</button>
		</span>
	)
}

export function IconLink({
	name,
	label,
	route,
	title,
	variant,
}: Props & { route: RouteLinkRoute }) {
	return (
		<span className={s.Container}>
			<RouteLink route={route}>
				<a className={cn('btn', variant ? `btn-${variant}` : 'btn-unstyled')} title={title}>
					<span className={s.Inside}>
						<span className={s.Icon}>
							<Icon name={name} />
						</span>
						{label && (
							<>
								{' '}
								<span className={s.Container}>{label}</span>
							</>
						)}
					</span>
				</a>
			</RouteLink>
		</span>
	)
}
