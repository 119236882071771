import { queryCache, useMutation } from 'react-query'
import { useUserContent } from './useContember'

export function useDeleteEntity(
	q:
		| 'deleteBuilding'
		| 'deleteHousingUnit'
		| 'deleteProject'
		| 'deleteHousingUnitTemplate'
		| 'deleteUser'
		| 'deleteFolder'
		| 'deleteDocument',
	invalidate: string[] = []
) {
	const content = useUserContent()

	return useMutation(async (id: string) => {
		const res = await content.mutation({
			[q]: [
				{ by: { id } },
				{
					ok: true,
					errorMessage: true,
				},
			],
		})

		invalidate.forEach((key) => {
			queryCache.invalidateQueries([key])
		})

		return res[q]
	})
}
