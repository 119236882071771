export async function areYouSure<T>(message = 'Opravdu chcete provést tuto akci?', data?: T) {
	return new Promise((resolve, reject) => {
		const response = confirm(message)
		if (response) {
			resolve(data)
		} else {
			reject()
		}
	})
}
